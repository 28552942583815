@import 'swiper/css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';


.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; /* Background color behind the animation */
  z-index: 9999;
  overflow: hidden; /* Prevents scrollbars during animation */
}

.loader-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff; /* Changed to white */
  
  /* Begin as a thin vertical strip at the left side (0 width) */
  clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
  
  /* Animate to cover the entire screen diagonally */
  animation: diagonalSlide 3s ease-in-out forwards;
}

.loader-image {
  z-index: 1;    /* Ensures logo is above the background */
  width: 150px;  /* Adjust logo size as needed */
}

@keyframes diagonalSlide {
  0% {
    clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
  }
  /* Halfway through, the polygon covers about half the screen with a tilt */
  50% {
    clip-path: polygon(0 0, 0 100%, 50% 100%, 65% 0);
  }
  /* Finally, it covers the entire width, maintaining a diagonal line */
  100% {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }
}
